.rooms {
  position: relative;
  padding-bottom: 30px;

  #rooms {
    position: absolute;
    top: -75px;
  }

  .title {
    margin-bottom: 21px;
  }

  &__item {
    border: 1px solid #ebebeb;

    &:not(:last-child) {
      margin-bottom: 15px;
    }

    &.hidden {
      height: 0;
      margin: 0;
      border: none;
      overflow: hidden;
      visibility: hidden;
    }
  }

  &__slider {
    position: relative;
  }

  &__percent {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    z-index: 10;
    top: 13px;
    right: 16px;
    width: 27px;
    height: 27px;
    border-radius: 50%;
    font-weight: 700;
    color: #fff;
    background-color: map-get($colors, 'primary');
  }

  .swiper-container {
    padding-bottom: 36px;
  }

  &__slide {
    display: block;
    position: relative;
    padding-bottom: 74%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__slider-pagination {
    position: absolute;
    left: 50%;
    bottom: 15px;
    transform: translateX(-50%);

    .swiper-pagination {
      display: flex;
    }

    .swiper-pagination-bullet {
      width: 6px;
      height: 6px;
      border-radius: 50%;
      background-color: #c4c4c4;

      &:not(:last-child) {
        margin-right: 7px;
      }

      &-active {
        background-color: map-get($colors, 'primary');
      }
    }
  }

  &__slider-nav {
    display: flex;
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: calc(100% - 36px);

    .swiper-button-prev {
      position: absolute;
      top: 50%;
      left: 11px;
      transform: translateY(-50%);
      z-index: 1;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.57);
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: 9px;
        left: 11px;
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
        border-left: 1px solid #000;
        border-bottom: 1px solid #000;
      }

      // @include on-event {
      //   &::before {
      //     background-color: map-get($colors, 'goldDark');
      //   }
      // }
    }

    .swiper-button-next {
      position: absolute;
      top: 50%;
      right: 11px;
      transform: translateY(-50%);
      z-index: 1;
      width: 27px;
      height: 27px;
      border-radius: 50%;
      background-color: rgba(255, 255, 255, 0.57);
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: 9px;
        right: 11px;
        transform: rotate(225deg);
        width: 10px;
        height: 10px;
        border-left: 1px solid #000;
        border-bottom: 1px solid #000;
      }

      // @include on-event {
      //   &::before {
      //     background-color: map-get($colors, 'goldDark');
      //   }
      // }
    }
  }

  &__body {
    padding: 0 15px 17px;
  }

  &__name {
    line-height: 21px;
    margin-bottom: 7px;
    font-weight: 700;
    text-transform: uppercase;
  }

  &__prices {
    display: flex;
    align-items: baseline;
    margin-bottom: 13px;
    padding-bottom: 3px;
    border-bottom: 1px solid map-get($colors, 'primary');

    del {
      line-height: 24px;
      margin-right: 5px;
      // font-size: 14px;
      font-size: 12px;
      color: #b9b9b9;
    }

    strong {
      line-height: 30px;
      // font-size: 18px;
      font-size: 16px;
      font-weight: 600;
      margin-right: 3px;
      color: map-get($colors, 'primary');
    }

    span {
      line-height: 18px;
      // font-size: 12px;
      font-size: 10px;
      font-weight: 500;
      color: #828282;
    }
  }

  &__block {
    display: flex;
    margin-bottom: 24px;
  }

  &__left {
    width: 55%;
  }

  &__right {
    width: 45%;
  }

  &__entity {
    display: flex;
    flex-direction: column;

    &:not(:last-child) {
      margin-bottom: 8px;
    }

    span {
      line-height: 21px;
      margin-bottom: 1px;
      color: #828282;
    }

    strong {
      line-height: 21px;
      font-weight: 600;
    }
  }

  &__action {
    display: flex;
    flex-direction: column;
  }

  &__booking {
    width: 100%;
    height: 48px;
    margin-bottom: 13px;
    font-weight: 600;
    color: #fff;
    background-color: map-get($colors, 'primary');
    transition: 0.2s;

    @include on-event {
      background-color: map-get($colors, 'primaryDark');
    }
  }

  &__calculate {
    width: 100%;
    height: 48px;
    border: 1px solid map-get($colors, 'primary');
    font-weight: 600;
    color: map-get($colors, 'primary');
    transition: 0.2s;

    @include on-event {
      color: #fff;
      background-color: map-get($colors, 'primary');
    }
  }

  &__bottom {
    display: flex;
    justify-content: center;
    padding-top: 22px;
  }

  &__more {
    position: relative;
    padding-right: 16px;
    font-size: 16px;
    font-weight: 600;
    color: map-get($colors, 'primary');
    transition: 0.2s;

    @include on-event {
      color: map-get($colors, 'primaryDark');
    }

    &::before {
      content: '';
      position: absolute;
      top: 5px;
      right: 0;
      transform: rotate(-45deg);
      width: 7px;
      height: 7px;
      border-left: 2px solid map-get($colors, 'primary');
      border-bottom: 2px solid map-get($colors, 'primary');
    }
  }

  &__appendix {
    display: flex;
    flex-direction: column;
    padding-top: 40px;
  }

  &__appendix-title {
    line-height: 30px;
    margin-bottom: 19px;
    font-size: 20px;
    font-weight: 600;
    // text-align: center;
  }

  &__appendix-subtitle {
    // line-height: 24px;
    // font-size: 16px;
    // text-align: center;
    h4 {
      line-height: 34px;
      font-size: 23px;
      font-weight: 600;
    }

    p {
      display: flex;
      flex-direction: column;

      &:not(:last-child) {
        margin-bottom: 20px;
      }

      b {
        line-height: 24px;
        margin-bottom: 7px;
        font-size: 16px;
        font-weight: 600;
      }

      span {
        line-height: 26px;
        margin-bottom: 5px;
        font-size: 16px;
      }

      strong {
        line-height: 24px;
        font-size: 16px;
        color: #828282;

        em {
          line-height: 30px;
          margin-right: 6px;
          font-size: 20px;
          font-weight: 600;
          color: map-get($colors, 'primary');
        }
      }
    }
  }
}

@media (min-width: 576px) {
  .rooms {
    &__prices {
      padding-bottom: 0;

      del {
        // line-height: 27px;
        font-size: 16px;
      }

      strong {
        font-size: 20px;
        line-height: 33px;
      }

      span {
        // line-height: 24px;
        font-size: 12px;
      }
    }
  }
}

@media (min-width: 768px) {
  .rooms {
    padding-bottom: 43px;

    #rooms {
      top: -95px;
    }

    .title {
      margin-bottom: 46px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }

    &__item {
      width: calc(50% - 30px);
      margin: 0 15px;
      margin-bottom: 30px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }

    .swiper-container {
      padding-bottom: 26px;
    }

    &__slide {
      padding-bottom: 70%;
    }

    &__slider-pagination {
      bottom: 12px;
    }

    &__name {
      margin-bottom: 2px;
    }

    &__prices {
      padding-bottom: 0;

      del {
        // line-height: 27px;
        font-size: 16px;
      }

      strong {
        line-height: 33px;
        font-size: 20px;
      }

      span {
        // line-height: 24px;
        font-size: 12px;
      }
    }

    &__block {
      margin-bottom: 19px;
    }

    &__action {
      flex-direction: row;
      justify-content: space-between;
    }

    &__booking {
      width: calc(50% - 5px);
      margin-bottom: 0;
    }

    &__calculate {
      width: calc(50% - 5px);
    }

    &__bottom {
      padding-top: 0;
    }

    &__appendix-title {
      max-width: 550px;
      line-height: 34px;
      margin-bottom: 11px;
      font-size: 23px;
      text-align: left;
    }

    &__appendix-subtitle {
      h4 {
        margin-bottom: 11px;
      }

      p {
        flex-direction: row;
        align-items: center;

        &:not(:last-child) {
          margin-bottom: 8px;
        }

        b {
          margin-right: 13px;
          margin-bottom: 0px;
        }

        span {
          margin-right: 17px;
          margin-bottom: 0;
        }

        // strong {
        //   line-height: 24px;
        //   font-size: 16px;
        //   color: #828282;

        //   em {
        //     line-height: 30px;
        //     margin-right: 6px;
        //     font-size: 20px;
        //     font-weight: 600;
        //     color: map-get($colors, 'primary');
        //   }
        // }
      }
    }
  }
}

@media (min-width: 1200px) {
  .rooms {
    padding-bottom: 62px;

    .title {
      margin-bottom: 49px;
    }

    &__item {
      width: calc(33.33333% - 30px);
    }

    &__prices {
      del {
        margin-right: 9px;
      }
    }

    &__appendix {
      flex-direction: row;
      padding-top: 69px;
    }

    &__appendix-title {
      max-width: 360px;
      margin-right: auto;
    }
  }
}
