.gallery {
  margin-bottom: 38px;

  img {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    object-fit: cover;
  }

  &__first {
    display: flex;
    flex-wrap: wrap;
    margin-bottom: 12px;
  }

  &__image-1 {
    position: relative;
    width: 100%;
    margin-bottom: 12px;
    padding-bottom: 74%;
  }

  &__image-2 {
    position: relative;
    width: 62%;
    padding-bottom: 50%;
  }

  &__image-3 {
    position: relative;
    width: calc(38% - 12px);
    margin-left: auto;
  }

  &__second {
    display: flex;
    flex-wrap: wrap;
  }

  &__image-4 {
    position: relative;
    width: calc(38% - 12px);
    margin-right: auto;
  }

  &__image-5 {
    position: relative;
    width: 62%;
    padding-bottom: 50%;
  }

  &__image-6 {
    display: none;
  }
}

@media (min-width: 768px) {
  .gallery {
    margin-bottom: 60px;

    &__content {
      display: flex;
      justify-content: space-between;
    }

    &__first {
      width: calc(50% - 15px);
      margin-bottom: 0;
    }

    &__image-1 {
      margin-bottom: 30px;
      padding-bottom: 70%;
    }

    &__image-2 {
      width: 60%;
      padding-bottom: 48%;
    }

    &__image-3 {
      width: calc(40% - 30px);
      padding-bottom: 48%;
    }

    &__second {
      width: calc(50% - 15px);
    }

    &__image-4 {
      width: 60%;
      margin-bottom: 30px;
      padding-bottom: 48%;
    }

    &__image-5 {
      width: calc(40% - 30px);
      margin-bottom: 30px;
      padding-bottom: 48%;
    }

    &__image-6 {
      display: block;
      position: relative;
      width: 100%;
      padding-bottom: 70%;
    }
  }
}

@media (min-width: 1200px) {
  .gallery {
    margin-bottom: 98px;

    &__content {
      position: relative;
    }

    &__image-1 {
      padding-bottom: 74%;
    }

    &__image-6 {
      padding-bottom: 74%;
    }
  }
}
