.question {
  padding-top: 30px;
  padding-bottom: 52px;
  background-position: right;
  background-repeat: no-repeat;
  background-size: 0;
  background-color: map-get($colors, 'primary');

  &__title {
    line-height: 47px;
    margin-bottom: 12px;
    font-size: 35px;
    font-weight: 700;
    color: #fff;
  }

  &__text {
    line-height: 24px;
    margin-bottom: 43px;
    font-size: 16px;
    color: #fff;
  }

  &__name {
    margin-bottom: 15px;
  }

  &__phone {
    margin-bottom: 10px;
  }

  .policy {
    margin-bottom: 22px;
  }

  .policy__checkbox {
    border-color: #fff;

    input:checked + span {
      border-color: #fff;
    }
  }

  .policy__text {
    color: rgba(255, 255, 255, 0.4);
  }

  .policy__button {
    color: #fff;
  }

  &__confirm {
    width: 100%;
    height: 55px;
    font-size: 16px;
    font-weight: 600;
    color: map-get($colors, 'primary');
    background-color: #fff;
    transition: 0.2s;

    @include on-event {
      color: #fff;
      background-color: map-get($colors, 'primary');
    }
  }
}

@media (min-width: 768px) {
  .question {
    padding-top: 45px;
    background-size: 165%;
    background-position: -100% 0;

    &__content {
      position: relative;
    }

    &__title {
      position: absolute;
      top: 0;
      left: 63px;
      max-width: 230px;
      line-height: 52px;
    }

    &__text {
      width: 294px;
      margin-left: auto;
      margin-bottom: 20px;
    }

    &__form {
      width: 294px;
      margin-left: auto;
    }

    &__name {
      margin-bottom: 24px;
    }

    &__phone {
      margin-bottom: 15px;
    }
  }
}

@media (min-width: 1200px) {
  .question {
    padding-top: 73px;
    padding-bottom: 87px;
    background-position: 100%;
    background-size: 56%;

    &__content {
      padding-left: 296px;
    }

    &__title {
      top: 4px;
    }

    &__text {
      width: auto;
      margin-bottom: 24px;
    }

    &__form {
      display: flex;
      position: relative;
      width: auto;
    }

    &__name {
      flex-grow: 1;
      margin-right: 30px;
      margin-bottom: 0;
    }

    &__phone {
      flex-grow: 1;
      margin-right: 30px;
      margin-bottom: 0;
    }

    &__confirm {
      width: 220px;
    }

    .policy {
      position: absolute;
      top: calc(100% + 14px);
      left: 0;
    }
  }
}
