.cookie {
  display: none;
  flex-direction: column;
  position: fixed;
  z-index: 10;
  left: 20px;
  right: 20px;
  bottom: 40px;
  padding: 18px 20px 23px;
  background-color: map-get($colors, 'primary');

  &.active {
    display: flex;
  }

  &__text {
    line-height: 21px;
    margin-bottom: 22px;
    padding-right: 40px;
    color: #fff;
  }

  &__link {
    text-decoration: underline;
    cursor: pointer;
  }

  &__button {
    max-height: 50px;
    height: 50px;
    width: 187px;
    font-size: 16px;
    font-weight: 600;
    color: map-get($colors, 'primary');
    background-color: #fff;
  }

  &__close {
    position: absolute;
    top: 10px;
    right: 20px;
    font-size: 20px;
    color: #fff;
  }
}

@media (min-width: 768px) {
  .cookie {
    flex-direction: row;
    align-items: flex-end;
    max-width: 924px;
    margin: 0 auto;

    &__text {
      margin-right: 58px;
      margin-bottom: 0;
      padding-right: 0;
      padding-left: 20px;
    }

    &__button {
      flex: 0 0 238px;
    }

    &__close {
      left: 8px;
      right: auto;
      top: 3px;
    }
  }
}
