.add-review {
  width: 400px;
  max-width: 100%;
  padding: 72px 20px;

  &__title {
    margin-bottom: 17px;
    line-height: 42px;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
  }

  &__grade {
    margin-bottom: 21px;
  }

  &__grade-title {
    margin-bottom: 12px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
  }

  &__grade-range {
    position: relative;
    margin-bottom: 12px;
    padding-left: 10px;
    padding-right: 10px;

    &::before {
      content: '';
      position: absolute;
      top: 0;
      left: 0;
      height: 100%;
      width: 20px;
      background-color: map-get($colors, 'primary');
    }

    &::after {
      content: '';
      position: absolute;
      top: 0;
      right: 0;
      height: 100%;
      width: 20px;
      background-color: #f7f7f7;
    }

    &.noUi-target {
      height: 7px;
      border: none;
      border-radius: 0;
      background-color: #f7f7f7;
      box-shadow: none;
    }

    .noUi-connects {
      border-radius: 0;
    }

    .noUi-connect {
      background-color: map-get($colors, 'primary');
    }

    .noUi-handle {
      z-index: 1;
      width: 23px;
      height: 23px;
      transform: translateX(-6px);
      margin-top: -2px;
      border: none;
      border-radius: 50%;
      background-color: map-get($colors, 'primary');
      box-shadow: none;
      cursor: pointer;

      &::before,
      &::after {
        display: none;
      }

      @include on-event {
        outline: none;
      }
    }
  }

  &__grade-scale {
    display: flex;
    justify-content: space-between;
    padding: 0 6px;

    span {
      line-height: 24px;
      font-size: 16px;
      cursor: pointer;
    }
  }

  &__name {
    margin-bottom: 19px;
    border: 1px solid #828282;
  }

  &__email {
    margin-bottom: 19px;
    border: 1px solid #828282;
  }

  &__city {
    margin-bottom: 19px;
    border: 1px solid #828282;
  }

  &__text {
    margin-bottom: 19px;
    border: 1px solid #828282;
  }

  .policy {
    margin-bottom: 21px;
  }

  &__action {
    display: flex;
    justify-content: center;
  }

  &__submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: map-get($colors, 'primary');
    transition: 0.2s;

    @include on-event {
      background-color: map-get($colors, 'primaryDark');
    }
  }
}

@media (min-width: 768px) {
  .add-review {
    width: 570px;
    padding: 50px 100px;

    &__title {
      font-size: 24px;
    }
  }
}

@media (min-width: 1200px) {
  .add-review {
    width: 770px;
    padding: 70px 200px;

    &__title {
      font-size: 28px;
    }
  }
}
