.quiz {
  &__title {
    line-height: 34px;
    margin-bottom: 5px;
    font-size: 23px;
    font-weight: 500;
  }

  &__steps {
    line-height: 24px;
    margin-bottom: 15px;
    font-size: 16px;
    color: rgba(0, 0, 0, 0.29);
  }

  &__wrapper {
    overflow: hidden;
    margin-left: -20px;
    margin-right: -20px;

    &.bigger {
      margin-bottom: -500px;
      padding-bottom: 500px;
    }
  }

  &__slider {
    display: flex;
    transition: 0.2s;
  }

  &__section {
    display: flex;
    flex-direction: column;
    justify-content: center;
    flex: 1 0 100%;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__body {
    display: flex;
    flex-direction: column;
    margin-bottom: 20px;
  }

  &__start {
    position: relative;
    margin-bottom: 20px;

    &::before {
      content: '';
      position: absolute;
      top: 22px;
      right: 16px;
      transform: rotate(-45deg);
      width: 7px;
      height: 7px;
      border-left: 1px solid rgba(0, 0, 0, 0.32);
      border-bottom: 1px solid rgba(0, 0, 0, 0.32);
    }

    input {
      cursor: pointer;
    }
  }

  &__end {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 22px;
      right: 16px;
      transform: rotate(-45deg);
      width: 7px;
      height: 7px;
      border-left: 1px solid rgba(0, 0, 0, 0.32);
      border-bottom: 1px solid rgba(0, 0, 0, 0.32);
    }

    input {
      cursor: pointer;
    }
  }

  &__adults {
    margin-bottom: 20px;
  }

  &__children {
    margin-bottom: 20px;
  }

  &__name {
    margin-bottom: 20px;
  }

  &__phone {
    margin-bottom: 15px;
  }

  &__action {
    display: flex;
  }

  &__prev {
    position: relative;
    width: 55px;
    height: 55px;
    margin-right: 14px;
    border: 1px solid map-get($colors, 'primary');

    &::before {
      content: '';
      position: absolute;
      top: 50%;
      left: 50%;
      transform: translate(-50%, -50%);
      width: 23px;
      height: 2px;
      background-color: map-get($colors, 'primary');
    }

    &::after {
      content: '';
      position: absolute;
      top: 20px;
      left: 16px;
      transform: rotate(45deg);
      width: 14px;
      height: 14px;
      border-left: 2px solid map-get($colors, 'primary');
      border-bottom: 2px solid map-get($colors, 'primary');
    }
  }

  &__next {
    flex-grow: 1;
    height: 55px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: map-get($colors, 'primary');
    transition: 0.2s;

    @include on-event {
      background-color: map-get($colors, 'primaryDark');
    }
  }

  &__confirm {
    flex-grow: 1;
    height: 55px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: map-get($colors, 'primary');
  }
}

@media (min-width: 768px) {
  .quiz {
    width: 334px;
  }
}
