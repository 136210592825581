.input {
  display: flex;
  align-items: center;
  height: 55px;
  background-color: #fff;

  input {
    width: 100%;
    height: 100%;
    padding: 0 21px;
    border: none;
    font-family: inherit;
    font-size: 16px;
    background-color: transparent;

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
