.years {
  display: none;
  position: relative;

  &.active {
    display: block;
  }

  &__block {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    height: 55px;
    padding-left: 20px;
    padding-right: 33px;
    background-color: #fff;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 22px;
      right: 16px;
      transform: rotate(-45deg);
      width: 7px;
      height: 7px;
      border-left: 1px solid rgba(0, 0, 0, 0.32);
      border-bottom: 1px solid rgba(0, 0, 0, 0.32);
    }
  }

  &__label {
    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
  }

  &__items {
    display: flex;

    span {
      font-size: 16px;

      &:not(:last-child) {
        margin-right: 5px;
        &::after {
          content: ',';
        }
      }
    }
  }

  &__dropdown {
    display: none;
    position: absolute;
    z-index: 1;
    top: 100%;
    left: 0;
    width: 100%;
    padding: 27px 20px 23px;
    background-color: #fff;
    box-shadow: 0px 0px 20px rgba(152, 152, 152, 0.13);

    &.active {
      display: block;
    }
  }

  &__container {
    margin-bottom: 35px;
  }

  &__item {
    display: flex;
    justify-content: space-between;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 16px;
    }
  }

  &__title {
    line-height: 24px;
    font-size: 16px;
  }

  &__select {
    position: relative;

    &::before {
      content: '';
      position: absolute;
      top: 6px;
      right: 0;
      transform: rotate(-45deg);
      width: 7px;
      height: 7px;
      border-left: 1px solid rgba(0, 0, 0, 0.32);
      border-bottom: 1px solid rgba(0, 0, 0, 0.32);
    }

    select {
      appearance: none;
      position: relative;
      width: 100px;
      height: 24px;
      border: none;
      border-bottom: 1px solid #000;
      border-radius: 0;
      font-size: 16px;
      font-family: inherit;
      color: rgba(0, 0, 0, 0.62);
      background-color: transparent;
      cursor: pointer;
    }
  }

  &__action {
    display: flex;
    justify-content: center;
  }

  &__confirm {
    width: 100%;
    height: 45px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: map-get($colors, 'primary');
    transition: 0.2s;

    @include on-event {
      background-color: map-get($colors, 'primaryDark');
    }
  }
}
