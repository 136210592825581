.title {
  line-height: 28px;
  font-size: 25px;
  font-weight: 700;
}

body.overflow-hidden {
  overflow: hidden;
}

body {
  .pika-single {
    font-family: inherit;
    border: none;
    // border-radius: 10px;
    box-shadow: 0px 0px 20px rgba(171, 121, 51, 0.13);
  }

  .pika-lendar {
    margin: 25px;
  }

  .pika-title {
    margin-bottom: 29px;
  }

  .pika-label {
    font-size: 18px;
  }

  .pika-prev,
  .pika-next {
    opacity: 1;
  }

  .pika-table {
    th {
      font-size: 14px;
      font-weight: 400;
      color: rgba(126, 126, 126, 0.58);
    }

    abbr {
      text-decoration: none;
    }

    td {
      .pika-button {
        line-height: 20px;
        font-size: 16px;
        text-align: center;
        background-color: transparent;

        &:hover {
          border-radius: 0;
          color: #fff !important;
          background-color: map-get($colors, 'primary');
        }
      }

      &.is-today {
        .pika-button {
          color: map-get($colors, 'primary');
        }
      }

      &.is-disabled {
        .pika-button {
          background-color: transparent;
        }
      }

      &.is-selected {
        .pika-button {
          border-radius: 0;
          color: #fff;
          background-color: map-get($colors, 'primary');
          box-shadow: none;
        }
      }
    }
  }
}

@media (min-width: 768px) {
  .title {
    line-height: 39px;
    font-size: 35px;
  }
}
