.checkbox {
  $p: &;

  display: flex;
  flex-shrink: 0;
  position: relative;
  width: 20px;
  height: 20px;
  border: 1px solid #9d9d9d;

  input:checked + span {
    position: absolute;
    top: 4px;
    left: 3px;
    transform: rotate(-45deg);
    width: 12px;
    height: 6px;
    border-left: 1px solid #000;
    border-bottom: 1px solid #000;
    cursor: pointer;
  }

  input {
    width: 100%;
    height: 100%;
    margin: 0;
    opacity: 0;
    cursor: pointer;
  }
}
