.thanks {
  padding: 72px 20px;

  &__content {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__icon {
    width: 50px;
    margin-bottom: 25px;
  }

  &__title {
    margin-bottom: 23px;
    font-size: 18px;
    font-weight: 600;
    text-align: center;
  }

  &__text {
    max-width: 220px;
    line-height: 24px;
    font-size: 16px;
    text-align: center;
  }
}

@media (min-width: 768px) {
  .thanks {
    width: 570px;
    padding: 50px 100px;

    &__title {
      font-size: 24px;
    }

    &__text {
      line-height: 29px;
      font-size: 18px;
    }
  }
}

@media (min-width: 1200px) {
  .thanks {
    width: 770px;
    padding: 70px 140px;

    &__title {
      font-size: 28px;
    }
  }
}
