.booking {
  width: 400px;
  max-width: 100%;
  padding: 72px 20px;

  &__title {
    line-height: 42px;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
  }

  &__subtitle {
    line-height: 24px;
    margin-bottom: 37px;
    font-size: 16px;
    text-align: center;
  }

  &__start {
    position: relative;
    margin-bottom: 19px;
    border: 1px solid #828282;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 22px;
      right: 16px;
      transform: rotate(-45deg);
      width: 7px;
      height: 7px;
      border-left: 1px solid rgba(0, 0, 0, 0.32);
      border-bottom: 1px solid rgba(0, 0, 0, 0.32);
    }

    input {
      cursor: pointer;
    }
  }

  &__start--span {
    position: absolute;
    top: 100%;
    left: 0;
  }

  &__end {
    position: relative;
    margin-bottom: 19px;
    border: 1px solid #828282;
    cursor: pointer;

    &::before {
      content: '';
      position: absolute;
      top: 22px;
      right: 16px;
      transform: rotate(-45deg);
      width: 7px;
      height: 7px;
      border-left: 1px solid rgba(0, 0, 0, 0.32);
      border-bottom: 1px solid rgba(0, 0, 0, 0.32);
    }

    input {
      cursor: pointer;
    }
  }

  &__end--span {
    position: absolute;
    top: 100%;
    left: 0;
  }

  &__adults {
    margin-bottom: 19px;
    border: 1px solid #828282;
  }

  &__children {
    margin-bottom: 19px;
    border: 1px solid #828282;
  }

  &__years {
    margin-bottom: 19px;

    & .years {
      border: 1px solid #828282;

      &__dropdown {
        left: -1px;
        right: -1px;
        width: auto;
      }
    }
  }

  &__name {
    margin-bottom: 19px;
    border: 1px solid #828282;
  }

  &__phone {
    margin-bottom: 19px;
    border: 1px solid #828282;
  }

  .policy {
    margin-bottom: 26px;
  }

  &__action {
    display: flex;
    justify-content: center;
  }

  &__submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: map-get($colors, 'primary');
    transition: 0.2s;

    @include on-event {
      background-color: map-get($colors, 'primaryDark');
    }
  }
}

@media (min-width: 768px) {
  .booking {
    width: auto;
    padding: 50px 100px;

    &__title {
      font-size: 24px;
    }
  }
}

@media (min-width: 1200px) {
  .booking {
    width: 770px;
    padding: 70px 200px;

    &__title {
      font-size: 28px;
    }
  }
}
