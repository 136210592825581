.screen {
  margin-bottom: 51px;
  padding-top: 66px;

  &__picture {
    margin-left: -20px;
    margin-right: -20px;
    margin-bottom: 26px;
  }

  &__image {
    display: flex;
    position: relative;
    padding-bottom: 65%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__title {
    max-width: 280px;
    line-height: 40px;
    margin-bottom: 3px;
    font-size: 34px;
    font-weight: 600;
  }

  &__subtitle {
    line-height: 26px;
    margin-bottom: 23px;
    font-size: 16px;
    font-weight: 600;
    text-transform: uppercase;
  }

  &__text {
    line-height: 26px;
    margin-bottom: 15px;
    font-size: 16px;
  }

  &__block {
    margin-bottom: 23px;
    padding: 10px;
    background-color: map-get($colors, 'primary');
  }

  &__block-title {
    line-height: 19px;
    margin-bottom: 11px;
    font-size: 13px;
    font-weight: 600;
    text-transform: uppercase;
    color: #fff;
  }

  &__block-subtitle {
    line-height: 21px;
    font-size: 14px;
    color: rgba(255, 255, 255, 0.62);
  }

  &__action {
    display: flex;
    flex-direction: column;
  }

  &__booking {
    width: 205px;
    height: 55px;
    margin-bottom: 20px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: map-get($colors, 'primary');
    transition: 0.2s;

    @include on-event {
      background-color: map-get($colors, 'primaryDark');
    }
  }

  &__calculate {
    width: 205px;
    height: 55px;
    border: 1px solid map-get($colors, 'primary');
    font-size: 16px;
    font-weight: 600;
    color: map-get($colors, 'primary');
    transition: 0.2s;

    @include on-event {
      color: #fff;
      background-color: map-get($colors, 'primary');
    }
  }
}

@media (min-width: 576px) {
  .screen {
    &__block-title {
      line-height: 23px;
      margin-bottom: 8px;
      font-size: 14px;
    }

    &__picture {
      margin-left: calc((100vw - 540px) / -2);
      margin-right: calc((100vw - 540px) / -2);
    }
  }
}

@media (min-width: 768px) {
  .screen {
    padding-top: 82px;

    &__content {
      display: flex;
    }

    &__picture {
      flex-grow: 1;
      order: 2;
      margin-left: 0;
      margin-right: calc((100vw - 720px) / -2);
      margin-bottom: 0;
    }

    &__image {
      height: 100%;
      padding-bottom: 0;
    }

    &__textual {
      width: 322px;
      padding-right: 19px;
    }

    &__title {
      line-height: 45px;
      margin-bottom: 8px;
      font-size: 37px;
    }

    &__subtitle {
      line-height: 28px;
      margin-top: -9px;
      margin-bottom: 18px;
      font-size: 17px;
    }

    &__text {
      margin-bottom: 18px;
    }

    &__block-title {
      line-height: 23px;
      margin-bottom: 8px;
      font-size: 14px;
    }

    &__booking {
      width: 223px;
    }

    &__calculate {
      width: 223px;
    }
  }
}

@media (min-width: 1200px) {
  .screen {
    margin-bottom: 100px;

    &__picture {
      margin-right: calc((100vw - 1140px) / -2);
    }

    &__textual {
      width: 497px;
      padding-top: 79px;
      padding-right: 62px;
      padding-bottom: 131px;
    }

    &__title {
      max-width: none;
      line-height: 80px;
      font-size: 43px;
    }

    &__subtitle {
      margin-bottom: 23px;
    }

    &__text {
      line-height: 29px;
      margin-bottom: 58px;
      font-size: 18px;
    }

    &__block {
      margin-bottom: 57px;
      padding: 13px 28px 11px;
    }

    &__block-title {
      line-height: 29px;
      margin-bottom: 3px;
      font-size: 18px;
    }

    &__block-subtitle {
      line-height: 24px;
      font-size: 16px;
    }

    &__action {
      flex-direction: row;
      justify-content: space-between;
    }

    &__booking {
      width: calc(50% - 15px);
      margin-bottom: 0;
    }

    &__calculate {
      width: calc(50% - 15px);
    }
  }
}
