.callback {
  width: 400px;
  max-width: 100%;
  padding: 72px 20px;

  &__title {
    line-height: 42px;
    margin-bottom: 37px;
    font-size: 28px;
    font-weight: 600;
    text-align: center;
  }

  &__name {
    margin-bottom: 19px;
    border: 1px solid #828282;
  }

  &__phone {
    margin-bottom: 14px;
    border: 1px solid #828282;
  }

  .policy {
    margin-bottom: 21px;
  }

  &__action {
    display: flex;
    justify-content: center;
  }

  &__submit {
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
    height: 55px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: map-get($colors, 'primary');
    transition: 0.2s;

    @include on-event {
      background-color: map-get($colors, 'primaryDark');
    }
  }
}

@media (min-width: 768px) {
  .callback {
    width: 570px;
    padding: 50px 100px;

    &__title {
      font-size: 24px;
    }
  }
}

@media (min-width: 1200px) {
  .callback {
    width: 770px;
    padding: 70px 200px;

    &__title {
      font-size: 28px;
    }
  }
}
