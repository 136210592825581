.textual {
  width: 400px;
  max-width: 100%;
  padding: 72px 20px;

  &__title {
    line-height: 30px;
    margin-bottom: 30px;
    font-size: 20px;
    font-weight: 600;
    text-align: center;
  }

  &__text {
    line-height: 24px;
    font-size: 16px;

    p {
      &:not(:last-child) {
        margin-bottom: 16px;
      }
    }
  }
}

@media (min-width: 768px) {
  .textual {
    width: 570px;
    padding: 50px 50px;

    &__title {
      line-height: 36px;
      font-size: 24px;
    }
  }
}

@media (min-width: 1200px) {
  .textual {
    width: 770px;
    padding: 70px 100px;

    &__title {
      line-height: 42px;
      font-size: 28px;
    }
  }
}
