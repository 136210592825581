.contacts {
  position: relative;
  margin-bottom: 55px;
  padding-top: 25px;

  &.hide {
    z-index: -1;
  }

  #contacts {
    position: absolute;
    top: -65px;
  }

  .title {
    margin-bottom: 33px;
  }

  &__list {
    margin-bottom: 33px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 27px;
    }
  }

  &__image {
    margin-bottom: 10px;
  }

  &__name {
    line-height: 30px;
    font-size: 20px;
    font-weight: 600;
  }

  &__value {
    line-height: 24px;
    font-size: 16px;

    a {
      transition: 0.2s;

      @include on-event {
        color: map-get($colors, 'primary');
      }
    }
  }

  &__map {
    position: relative;
    margin-left: -20px;
    margin-right: -20px;
    padding-bottom: 100%;
  }

  #map {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
  }
}

@media (min-width: 576px) {
  .contacts {
    &__map {
      margin-left: calc((100vw - 540px) / -2);
      margin-right: calc((100vw - 540px) / -2);
    }
  }
}

@media (min-width: 768px) {
  .contacts {
    padding-top: 55px;

    #contacts {
      top: -41px;
    }

    .title {
      max-width: 320px;
      margin-bottom: 47px;
    }

    &__list {
      display: flex;
      justify-content: space-between;
      margin-bottom: 47px;
    }

    &__item {
      max-width: 100%;
      width: 322px;

      &:first-child {
        width: 140px;
      }

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }

    &__map {
      margin-left: calc((100vw - 720px) / -2);
      margin-right: calc((100vw - 720px) / -2);
      padding-bottom: 60%;
    }
  }
}

@media (min-width: 1200px) {
  .contacts {
    margin-bottom: 100px;
    padding-top: 80px;

    .container {
      position: relative;
    }

    .title {
      position: absolute;
      top: 0;
      right: 0;
      max-width: 420px;
      line-height: 52px;
      margin-bottom: 0;
      padding-left: 72px;
    }

    &__content {
      display: flex;
    }

    &__list {
      order: 2;
      flex-direction: column;
      justify-content: flex-start;
      width: 420px;
      margin-bottom: 0;
      padding-top: 151px;
      padding-left: 72px;
    }

    &__item {
      &:first-child {
        margin-bottom: 39px;
      }

      &:nth-child(2) {
        margin-bottom: 23px;
      }
    }

    &__name {
      line-height: 34px;
      margin-bottom: 7px;
      font-size: 23px;
    }

    &__map {
      flex-grow: 1;
      height: 659px;
      margin-left: calc((100vw - 1140px) / -2);
      margin-right: 0;
      padding-bottom: 0;
    }
  }
}
