.policy {
  display: flex;

  &__checkbox {
    margin-right: 10px;
  }

  &__block {
    line-height: 19px;
  }

  &__text {
    color: rgba(0, 0, 0, 0.4);
  }

  &__button {
    text-decoration: underline;
    color: #000;
    cursor: pointer;
  }
}
