body.loaded {
  .modal {
    transition: 0.3s;
  }
}

.modal {
  display: flex;
  visibility: hidden;
  opacity: 0;
  position: fixed;
  z-index: 1000;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  padding: 15px;
  overflow-y: auto;
  background-color: rgba(0, 0, 0, 0.7);

  &.active {
    visibility: visible;
    opacity: 1;

    .modal__content {
      opacity: 1;
      transform: translateY(0);
    }
  }

  &__content {
    position: relative;
    transform: translateY(-100px);
    max-width: 100%;
    margin: 40px auto auto;
    opacity: 0;
    transition: 0.3s;
    background-repeat: no-repeat;
    background-color: #fff;
    background-position: -170px bottom;
    background-size: 60%;
  }

  &__close {
    display: flex;
    justify-content: center;
    align-items: center;
    position: absolute;
    top: 10px;
    right: 10px;
    width: 40px;
    height: 40px;
    font-size: 20px;
  }
}
