.about {
  $p: &;

  position: relative;
  margin-bottom: 31px;
  background-position: -50%, -50%;
  background-repeat: no-repeat;

  #about {
    position: absolute;
    top: -75px;
  }

  &__bg {
    display: none;
  }

  .title {
    margin-bottom: 21px;
  }

  &__title {
    line-height: 27px;
    margin-bottom: 21px;
    font-size: 18px;
    font-weight: 500;
  }

  &__texts {
    margin-bottom: 19px;

    &.active {
      #{$p}__text-2 {
        display: block;
      }

      #{$p}__more {
        display: none;
      }
    }
  }

  &__text {
    line-height: 24px;
    margin-bottom: 9px;
    font-size: 16px;
  }

  &__text-2 {
    display: none;
    line-height: 24px;
    margin-bottom: 9px;
    font-size: 16px;
  }

  &__more {
    text-decoration: underline;
    color: map-get($colors, 'primary');
  }

  &__block {
    display: flex;
    flex-direction: column;
  }

  &__item {
    display: flex;
    align-items: center;

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    strong {
      line-height: 61px;
      margin-right: 16px;
      font-size: 50px;
      font-weight: 600;
      color: map-get($colors, 'primary');
    }

    span {
      max-width: 155px;
      line-height: 24px;
      font-size: 16px;
    }
  }
}

@media (min-width: 768px) {
  .about {
    margin-top: -51px;
    padding-top: 51px;
    margin-bottom: 60px;
    background-position: -400%, -200%;

    #about {
      top: -5px;
    }

    .title {
      margin-bottom: 40px;
    }

    &__title {
      line-height: 37px;
      margin-bottom: 29px;
      font-size: 25px;
    }

    &__texts {
      margin-bottom: 40px;
    }

    &__text {
      margin-bottom: 40px;
    }

    &__text-2 {
      display: block;
      // line-height: 24px;
      margin-bottom: 0;
      // font-size: 16px;
    }

    &__more {
      display: none;
    }

    &__block {
      flex-direction: row;
      justify-content: space-between;
      align-items: center;
    }

    &__item {
      &:not(:last-child) {
        margin-bottom: 0;
      }

      strong {
        line-height: 67px;
        margin-right: 10px;
        font-size: 55px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .about {
    margin-top: -100px;
    padding-top: 100px;
    margin-bottom: 42px;
    overflow: hidden;

    &__bg {
      display: block;
      position: absolute;
      top: -300px;
      right: -300px;
    }

    &__content {
      position: relative;
    }

    &__title {
      margin-bottom: 32px;
    }

    &__texts {
      display: flex;
      justify-content: space-between;
    }

    &__text {
      margin-bottom: 0;
      padding-right: 10px;
    }

    &__text-2 {
      padding-left: 10px;
    }

    &__item {
      strong {
        line-height: 73px;
        margin-right: 18px;
        font-size: 60px;
      }
    }
  }
}
