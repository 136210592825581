.select {
  display: flex;
  justify-content: space-between;
  align-items: center;
  position: relative;
  height: 55px;
  background-color: #fff;
  cursor: pointer;

  &::before {
    content: '';
    position: absolute;
    top: 22px;
    right: 16px;
    transform: rotate(-45deg);
    width: 7px;
    height: 7px;
    border-left: 1px solid rgba(0, 0, 0, 0.32);
    border-bottom: 1px solid rgba(0, 0, 0, 0.32);
  }

  span {
    position: absolute;
    left: 20px;

    font-size: 16px;
    color: rgba(0, 0, 0, 0.4);
  }

  select {
    position: relative;
    appearance: none;
    width: 100%;
    height: 100%;
    padding-left: calc(100% - 50px);
    border: none;
    font-family: inherit;
    font-size: 16px;
    background-color: transparent;
    cursor: pointer;

    &.long {
      padding-left: calc(100% - 120px);
    }
  }
}
