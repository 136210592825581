.footer {
  &__top {
    padding-top: 27px;
    padding-bottom: 44px;
    background-color: map-get($colors, 'primary');
  }

  &__block {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  &__logo {
    width: 200px;
    margin-bottom: 35px;

    img {
      max-height: 100%;
    }
  }

  &__credentials {
    display: flex;
    flex-direction: column;
    align-items: flex-start;
  }

  &__phone {
    line-height: 27px;
    margin-bottom: 10px;
    font-size: 18px;
    font-weight: 600;
    color: #fff;
  }

  &__callback {
    height: 46px;
    padding: 0 15px;
    border: 1px solid #fff;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    transition: 0.2s;

    @include on-event {
      color: map-get($colors, 'primary');
      background-color: #fff;
    }
  }

  &__nav {
    display: none;
  }

  &__bottom {
    padding-top: 18px;
    padding-bottom: 22px;
  }

  &__social {
    display: flex;
    justify-content: center;
    margin-bottom: 25px;
  }

  &__social-item {
    &:not(:last-child) {
      margin-right: 41px;
    }

    img {
      width: 22px;
    }
  }

  &__buttons {
    display: flex;
    flex-direction: column;
  }

  &__policy {
    line-height: 21px;
    margin-bottom: 15px;
    // transition: 0.2s;

    // @include on-event {
    //   text-decoration: underline;
    // }
  }

  &__rules {
    line-height: 21px;
    margin-bottom: 15px;
    // transition: 0.2s;

    // @include on-event {
    //   text-decoration: underline;
    // }
  }

  &__cookie {
    line-height: 21px;
    margin-bottom: 15px;
    // transition: 0.2s;

    // @include on-event {
    //   text-decoration: underline;
    // }
  }
}

@media (min-width: 768px) {
  .footer {
    &__top {
      padding-top: 30px;
      padding-bottom: 35px;
    }

    &__block {
      flex-direction: row;
      justify-content: space-between;
    }

    &__logo {
      width: 180px;
      margin-bottom: 0;
    }

    &__credentials {
      flex-direction: row;
      align-items: center;
    }

    &__phone {
      margin-right: 15px;
      margin-bottom: 0;
    }

    &__bottom {
      padding-top: 21px;
      padding-bottom: 18px;
    }

    &__social {
      margin-bottom: 21px;
    }

    &__buttons {
      flex-direction: row;
      justify-content: space-between;
    }

    &__policy {
      margin-bottom: 0;
    }

    &__rules {
      margin-bottom: 0;
    }

    &__cookie {
      margin-bottom: 0;
    }
  }
}

@media (min-width: 1200px) {
  .footer {
    &__top {
      padding-top: 40px;
    }

    &__block {
      align-items: flex-start;
      margin-bottom: 0;
    }

    &__logo {
      width: 200px;
    }

    &__phone {
      margin-right: 20px;
    }

    &__nav {
      display: flex;
      justify-content: flex-end;
    }

    &__nav-item {
      color: #fff;
      opacity: 0.7;

      &:not(:last-child) {
        margin-right: 46px;
        padding-right: 5px;
      }

      @include on-event {
        text-shadow: -0.25px -0.25px 0 #fff, 0.25px 0.25px #fff;
        opacity: 1;
      }
    }

    &__bottom {
      padding-top: 18px;
      padding-bottom: 20px;
    }

    &__bottom-content {
      display: flex;
      justify-content: space-between;
      align-items: center;
    }

    &__social {
      order: 2;
      margin-bottom: 0;
    }

    &__policy {
      margin-right: 67px;
    }

    &__rules {
      margin-right: 67px;
    }

    &__cookie {
      margin-right: 67px;
    }
  }
}
