.textarea {
  display: flex;
  height: 230px;
  background-color: #fff;

  textarea {
    width: 100%;
    height: 100%;
    padding: 10px 21px;
    border: none;
    font-family: inherit;
    font-size: 16px;
    background-color: transparent;
    resize: none;

    &::placeholder {
      color: rgba(0, 0, 0, 0.4);
    }
  }
}
