.around {
  margin-bottom: 55px;

  .title {
    margin-bottom: 23px;
  }

  &__item {
    display: flex;
    position: relative;
    min-height: 85px;
    padding-top: 17px;
    padding-left: 27px;
    padding-bottom: 23px;
    background-color: map-get($colors, 'primary');

    &:not(:last-child) {
      margin-bottom: 16px;
    }

    span {
      max-width: 122px;
      line-height: 22px;
      font-size: 16px;
      font-weight: 600;
      color: #fff;
    }

    img {
      position: absolute;
      right: 2px;
      bottom: 0;
      width: 53px;
    }
  }
}

@media (min-width: 768px) {
  .around {
    margin-bottom: 30px;

    .title {
      margin-bottom: 30px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }

    &__item {
      width: calc(33.33333% - 30px);
      margin: 0 15px;
      margin-bottom: 30px;
      padding-left: 24px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .around {
    margin-bottom: 91px;

    .title {
      margin-bottom: 59px;
    }

    &__item {
      width: calc(20% - 30px);
      margin-bottom: 0;
      padding-left: 20px;

      &:not(:last-child) {
        margin-bottom: 0;
      }
    }
  }
}
