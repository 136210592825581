.services {
  position: relative;
  margin-bottom: 46px;
  padding-top: 42px;
  padding-bottom: 38px;
  background-position: -50% 200px;

  background-repeat: no-repeat;
  background-color: #f2eae2;

  #services {
    position: absolute;
    top: -75px;
  }

  .title {
    margin-bottom: 29px;
  }

  &__item {
    &:not(:last-child) {
      margin-bottom: 29px;
    }
  }

  &__image {
    position: relative;
    margin-bottom: 27px;
    padding-bottom: 74%;

    img {
      position: absolute;
      top: 0;
      left: 0;
      width: 100%;
      height: 100%;
      object-fit: cover;
    }
  }

  &__name {
    line-height: 27px;
    font-size: 20px;
    font-weight: 500;
  }

  &__text {
    line-height: 24px;
    padding-top: 17px;
    font-size: 16px;
  }
}

@media (min-width: 768px) {
  .services {
    margin-bottom: 26px;
    background-position: 500px 200px;

    #services {
      top: -95px;
    }

    &__list {
      display: flex;
      flex-wrap: wrap;
      margin: 0 -15px;
    }

    &__item {
      width: calc(50% - 30px);
      margin: 0 15px;
      margin-bottom: 30px;

      &:not(:last-child) {
        margin-bottom: 30px;
      }
    }
  }
}

@media (min-width: 1200px) {
  .services {
    margin-bottom: 40px;
    background-position: 1100px 200px;

    .title {
      margin-bottom: 60px;
    }

    &__item {
      width: calc(33.33333% - 30px);
      margin-bottom: 60px;

      &:not(:last-child) {
        margin-bottom: 60px;
      }
    }
  }
}
