.header {
  $p: &;

  position: fixed;
  z-index: 100;
  top: 0;
  left: 0;
  width: 100%;
  height: 66px;
  background-color: #fff;

  &__content {
    display: flex;
    justify-content: space-between;
    align-items: center;
    position: relative;
    padding-top: 9px;
    // padding-bottom: 18px;
  }

  &__logo {
    display: flex;

    img {
      max-height: 100%;
      height: 50px;
      object-fit: cover;
    }
  }

  &__open {
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: flex-end;
    width: 32px;
    height: 16px;

    &::before {
      content: '';
      width: 100%;
      height: 2px;
      background-color: #000;
    }

    span {
      width: 22px;
      height: 2px;
      background-color: #000;
    }

    &::after {
      content: '';
      width: 100%;
      height: 2px;
      background-color: #000;
    }
  }

  &__block {
    display: none;
  }

  &--open {
    #{$p}__block {
      display: flex;
      flex-direction: column;
      position: fixed;
      top: 0;
      right: 0;
      width: 224px;
      padding: 24px 20px 31px;
      background-color: #fff;
      box-shadow: 0px 0px 20px rgba(152, 152, 152, 0.13);
    }

    #{$p}__nav {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
      margin-bottom: 26px;
    }

    #{$p}__close {
      position: relative;
      width: 23px;
      height: 23px;
      margin-bottom: 41px;

      &::before {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(45deg);
        width: 30px;
        height: 3px;
        border-radius: 1px;
        background-color: #000;
      }

      &::after {
        content: '';
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%) rotate(-45deg);
        width: 30px;
        height: 3px;
        border-radius: 1px;
        background-color: #000;
      }
    }

    #{$p}__nav-item {
      position: relative;
      width: 100%;
      padding: 15px 0;
      font-weight: 600;
      text-align: right;
      opacity: 0.7;

      &:not(:last-child) {
        border-bottom: 1px solid rgba(0, 0, 0, 0.03);
      }

      @include on-event {
        opacity: 1;

        &::before {
          content: '';
          position: absolute;
          right: 0;
          bottom: 9px;
          width: 16px;
          height: 2px;
          background-color: #000;
        }
      }
    }

    #{$p}__credentials {
      display: flex;
      flex-direction: column;
      align-items: flex-end;
    }

    #{$p}__phone {
      line-height: 27px;
      margin-bottom: 10px;
      font-size: 18px;
      font-weight: 600;
    }

    #{$p}__callback {
      line-height: 21px;
      font-weight: 600;
      color: map-get($colors, 'primary');
      border-bottom: 1px solid map-get($colors, 'primary');
      transition: 0.2s;

      &:hover {
        color: map-get($colors, 'primaryDark');
      }
    }
  }
}

@media (min-width: 768px) {
  .header {
    $p: &;

    height: 82px;

    &__content {
      padding-top: 5px;
      padding-bottom: 27px;
    }

    &__logo {
      margin-right: auto;

      img {
        height: 70px;
      }
    }

    &__open {
      order: 2;
    }

    &__block {
      display: flex;
    }

    &__nav {
      display: none;
    }

    &__credentials {
      display: flex;
      align-items: center;
      margin-right: 25px;
    }

    &__phone {
      line-height: 27px;
      margin-right: 16px;
      font-size: 18px;
      font-weight: 600;
    }

    &__callback {
      line-height: 21px;
      border-bottom: 1px solid map-get($colors, 'primary');
      font-weight: 600;
      color: map-get($colors, 'primary');

      @include on-event {
        color: map-get($colors, 'primaryDark');
      }
    }

    &--open {
      #{$p}__block {
        flex-direction: row;
        position: static;
        top: auto;
        right: auto;
        width: auto;
        padding: 0;
        background-color: transparent;
        box-shadow: none;
      }

      #{$p}__nav {
        position: fixed;
        top: 8px;
        right: 0;
        width: 224px;
        margin-bottom: 26px;
        padding: 24px 20px 31px;
        background-color: #fff;
        box-shadow: 0px 0px 20px rgba(152, 152, 152, 0.13);
      }

      #{$p}__credentials {
        flex-direction: row;
        align-items: center;
      }

      #{$p}__phone {
        margin-bottom: 0;
      }
    }
  }
}

@media (min-width: 1200px) {
  .header {
    &__content {
      justify-content: flex-start;
    }

    &__logo {
      margin-right: 0;
    }

    &__open {
      display: none;
    }

    &__block {
      flex-grow: 1;
    }

    &__nav {
      display: flex;
      align-items: center;
      margin-left: auto;
      margin-right: auto;
    }

    &__nav-item {
      position: relative;
      line-height: 21px;
      font-weight: 600;
      opacity: 0.7;

      &:not(:last-child) {
        margin-right: 48px;
      }

      @include on-event {
        opacity: 1;

        &::before {
          content: '';
          position: absolute;
          top: 100%;
          left: 0;
          width: 16px;
          height: 2px;
          background-color: #000;
        }
      }
    }

    &__credentials {
      margin-right: 0;
    }
  }
}
