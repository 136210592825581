.calculation {
  &__textual {
    margin-bottom: 26px;
  }

  &__textual-title {
    line-height: 37px;
    margin-bottom: 19px;
    font-size: 25px;
    font-weight: 700;
  }

  &__textual-text {
    line-height: 24px;
    font-size: 16px;
  }

  &__quiz {
    margin-left: -20px;
    margin-right: -20px;
    padding-top: 29px;
    padding-left: 20px;
    padding-right: 20px;
    padding-bottom: 41px;
    background-image: none !important;
    background-color: #f2eae2;
  }
}

@media (min-width: 576px) {
  .calculation {
    &__quiz {
      margin-left: calc((100vw - 540px) / -2);
      margin-right: calc((100vw - 540px) / -2);
    }
  }
}

@media (min-width: 768px) {
  .calculation {
    &__content {
      display: flex;
    }

    &__textual {
      width: 50%;
      margin-bottom: 0;
      padding-top: 60px;
      padding-right: 20px;
    }

    &__textual-title {
      line-height: 45px;
      margin-bottom: 56px;
      font-size: 30px;
    }

    &__textual-text {
      max-width: 300px;
    }

    &__quiz {
      flex-grow: 1;
      width: 50%;
      margin-left: 0;
      margin-right: calc((100vw - 720px) / -2);
      padding-top: 64px;
      padding-left: 30px;
    }
  }
}

@media (min-width: 1200px) {
  .calculation {
    &__textual {
      width: 454px;
      padding-right: 39px;
    }

    &__textual-title {
      line-height: 52px;
      margin-bottom: 32px;
      font-size: 35px;
    }

    &__quiz {
      width: auto;
      margin-right: calc((100vw - 1140px) / -2);
      padding-left: 140px;
      padding-bottom: 65px;
    }
  }
}
