.reviews {
  position: relative;
  margin-bottom: 52px;
  padding-top: 38px;

  #reviews {
    position: absolute;
    top: -45px;
  }

  .title {
    margin-bottom: 18px;
  }

  &__slider {
    margin-bottom: 30px;
  }

  .swiper-container {
    margin-left: -20px;
    margin-right: -20px;
    padding-left: 20px;
    padding-right: 20px;
  }

  &__slider-nav {
    display: flex;
    position: absolute;
    top: 0;
    left: 20px;
    right: 20px;
    height: 100%;

    .swiper-button-prev {
      position: absolute;
      top: 50%;
      left: 0;
      transform: translate(-50%, -50%);
      z-index: 1;
      width: 37px;
      height: 37px;
      border-radius: 50%;
      background-color: #ededed;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: 13px;
        left: 16px;
        transform: rotate(45deg);
        width: 10px;
        height: 10px;
        border-left: 1px solid #000;
        border-bottom: 1px solid #000;
      }

      // @include on-event {
      //   &::before {
      //     background-color: map-get($colors, 'goldDark');
      //   }
      // }
    }

    .swiper-button-next {
      position: absolute;
      top: 50%;
      right: 0;
      transform: translate(50%, -50%);
      z-index: 1;
      width: 37px;
      height: 37px;
      border-radius: 50%;
      background-color: #ededed;
      cursor: pointer;

      &::before {
        content: '';
        position: absolute;
        top: 13px;
        right: 16px;
        transform: rotate(225deg);
        width: 10px;
        height: 10px;
        border-left: 1px solid #000;
        border-bottom: 1px solid #000;
      }
    }
  }

  &__slide {
    padding: 13px 24px 28px;
    border: 1px solid map-get($colors, 'primary');

    &.cut {
      .reviews__text {
        display: -webkit-box;
        -webkit-line-clamp: 5;
        -webkit-box-orient: vertical;
        overflow: hidden;
      }

      .reviews__more {
        // display: inline-block;
        visibility: visible;
      }
    }
  }

  &__grade {
    line-height: 30px;
    margin-bottom: 10px;
    font-size: 20px;
    font-weight: 600;
    color: map-get($colors, 'primary');
  }

  &__text {
    min-height: 120px;
    line-height: 24px;
    font-size: 16px;
  }

  &__action {
    margin-bottom: 21px;
  }

  &__more {
    line-height: 24px;
    font-size: 16px;
    font-weight: 500;
    text-decoration: underline;
    color: map-get($colors, 'primary');
    visibility: hidden;
    transition: 0.2s;

    @include on-event {
      color: map-get($colors, 'primaryDark');
    }
  }

  &__name {
    line-height: 30px;
    margin-bottom: 5px;
    font-size: 20px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
  }

  &__city {
    line-height: 21px;
    color: rgba(0, 0, 0, 0.7);
  }

  &__bottom {
    text-align: center;
  }

  &__add {
    width: 250px;
    height: 55px;
    font-size: 16px;
    font-weight: 600;
    color: #fff;
    background-color: map-get($colors, 'primary');
    transition: 0.2s;

    @include on-event {
      background-color: map-get($colors, 'primaryDark');
    }
  }
}

@media (min-width: 768px) {
  .reviews {
    padding-top: 54px;

    #reviews {
      top: -11px;
    }

    .title {
      margin-bottom: 60px;
    }

    &__slider {
      margin-left: 80px;
      margin-right: 80px;
      margin-bottom: 55px;
    }

    .swiper-container {
      margin-left: -80px;
      margin-right: -80px;
      padding-left: 80px;
      padding-right: 80px;
    }

    &__slider-nav {
      left: 0;
      right: 0;

      .swiper-button-prev {
        width: 61px;
        height: 61px;
        transform: translate(0, -50%);

        &::before {
          top: 21px;
          left: 25px;
          width: 18px;
          height: 18px;
        }
      }

      .swiper-button-next {
        width: 61px;
        height: 61px;
        transform: translate(0, -50%);

        &::before {
          top: 21px;
          right: 25px;
          width: 18px;
          height: 18px;
        }
      }
    }

    &__slide {
      padding: 34px 43px;
    }

    &__grade {
      line-height: 34px;
      margin-bottom: 16px;
      font-size: 23px;
    }

    &__text {
      min-height: 120px;
    }

    &__action {
      margin-bottom: 65px;
    }

    &__name {
      line-height: 34px;
      margin-bottom: 0;
      font-size: 23px;
    }
  }
}

@media (min-width: 1200px) {
  .reviews {
    margin-bottom: 93px;
    padding-top: 100px;

    &__slider {
      margin-left: 0;
      margin-right: 0;
      margin-bottom: 26px;
    }

    .swiper-container {
      margin-left: -30px;
      margin-right: -30px;
      padding-left: 30px;
      padding-right: 30px;
    }
  }
}
