.review {
  width: 400px;
  max-width: 100%;
  padding: 72px 20px;

  &__grade {
    line-height: 34px;
    margin-bottom: 16px;
    font-size: 23px;
    font-weight: 600;
    color: map-get($colors, 'primary');
  }

  &__text {
    line-height: 24px;
    margin-bottom: 41px;
    font-size: 16px;
  }

  &__name {
    line-height: 34px;
    font-size: 23px;
    font-weight: 600;
    color: rgba(0, 0, 0, 0.7);
  }

  &__city {
    line-height: 21px;
    color: rgba(0, 0, 0, 0.7);
  }
}

@media (min-width: 768px) {
  .review {
    width: 570px;
    padding: 50px 100px;

    &__text {
      margin-bottom: 14px;
      line-height: 24px;
      font-size: 16px;
    }
  }
}

@media (min-width: 1200px) {
  .review {
    width: 770px;
    padding: 70px 140px;

    &__title {
      font-size: 28px;
    }
  }
}
